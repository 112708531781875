import * as React from "react"

const Review = () => {
  return (
    <>
      <div className="stats-block rating-stars mb-3">
        <h2>2 Reviews</h2>
        <span className="pro-rating stars big">5.0</span>&nbsp;
        <i className="bi bi-star-fill review-stars active" />
        &nbsp;
        <i className="bi bi-star-fill review-stars active" />
        &nbsp;
        <i className="bi bi-star-fill review-stars active" />
        &nbsp;
        <i className="bi bi-star-fill review-stars active" />
        &nbsp;
        <i className="bi bi-star-fill review-stars active" />
        &nbsp;
      </div>

      <div className="reviewsContainer">
        <ul className="review-list">
          <li className="review ">
            <p className="review-rating-wrap">
              <span className="review-author">Gene Stukas Landlord</span>&nbsp;
              <span className="starFill" data-stars="5">
                <i className="bi bi-star-fill review-stars active" />
                &nbsp;
                <i className="bi bi-star-fill review-stars active" />
                &nbsp;
                <i className="bi bi-star-fill review-stars active" />
                &nbsp;
                <i className="bi bi-star-fill review-stars active" />
                &nbsp;
                <i className="bi bi-star-fill review-stars active" />
                &nbsp;
              </span>
            </p>
            <p className="review-body about-txt">
              First of all the plumbing job was done excellent! The quality is great and I am very satisfied.
              The human relations are trustworthy. If I would need new job, I would go to this company only. I
              would also recommend this company to all my friends without hesitation.
            </p>
          </li>
          <li className="review ">
            <p className="review-rating-wrap">
              <span className="review-author">Mr, Gene Stukas, and Mr. Valery Galperin</span>
              <span className="starFill" data-stars="5">
                <i className="bi bi-star-fill review-stars active" />
                &nbsp;
                <i className="bi bi-star-fill review-stars active" />
                &nbsp;
                <i className="bi bi-star-fill review-stars active" />
                &nbsp;
                <i className="bi bi-star-fill review-stars active" />
                &nbsp;
                <i className="bi bi-star-fill review-stars active" />
                &nbsp;
              </span>
            </p>
            <p className="review-body about-txt">
              First of all the plumbing job was done excellent! The quality is great and I am very satisfied.
              The human relations are trustworthy. If I would need new job, I would go to this company only. I
              would also recommend this company to all my friends without hesitation.
            </p>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Review
