import * as React from "react"
import GoogleMapReact from "google-map-react"

const Map = () => {
  const defaultProps = {
    center: [32.97656, -80.23291],
    zoom: 13,
    greatPlaceCoords: { lat: 32.97656, lng: -80.23291 },
  }

  return (
    <section id="map_container">
      <div className="row g-0">
        <div className="col-md-6">
          <div id="local_map" style={{ width: "100%", height: "350px" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyBvcAXg1ocsFA4q0Vj72erVYINdsi7vHDc" }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
            >
              <div
                style={{
                  width: "26px",
                  height: "37px",
                  overflow: "hidden",
                  position: "absolute",
                  left: "-13px",
                  top: "-37px",
                  "z-index": "0",
                }}
              >
                <img src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi3.png" alt="" />
              </div>
            </GoogleMapReact>
          </div>
        </div>
        <div className="col-md-6 d-none d-md-block">
          <div id="street_view" style={{ width: "100%", height: "350px" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyBvcAXg1ocsFA4q0Vj72erVYINdsi7vHDc" }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
            >
              <div
                style={{
                  width: "26px",
                  height: "37px",
                  overflow: "hidden",
                  position: "absolute",
                  left: "-13px",
                  top: "-37px",
                  "z-index": "0",
                }}
              >
                <img src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi3.png" alt="" />
              </div>
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Map
