import * as React from "react"

import Layout from "../components/Layout/layout"
import Seo from "../components/seo"

import Hero from "../components/Detail/hero"
import Gallery from "../components/Detail/gallery"
import Detail from "../components/Detail/detail"
import Map from "../components/Detail/map"

import "../components/Detail/local_detail.css"

const TridentElectricPage = () => {
  return (
    <Layout page="detail-template">
      <Seo title="Best Local Auto Mechanic" />
      <Hero />
      <Gallery />
      <Detail />
      <Map />
    </Layout>
  )
}

export default TridentElectricPage
