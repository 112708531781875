import * as React from "react"
import Faq from "./faq"
import Review from "./review"

const Detail = () => {
  return (
    <section className="py-5 detail_section">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <h2>About</h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
              the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
              of type and scrambled it to make a type specimen book. It has survived not only five centuries,
              but also the leap into electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
              and more recently with desktop publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              It is a long established fact that a reader will be distracted by the readable content of a page
              when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here, content here', making it look like
              readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will uncover many web sites still in
              their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on
              purpose (injected humour and the like).
            </p>

            <hr className="my-5" />

            <Review />

            <hr className="my-5" />

            <Faq />
          </div>
          <div className="col-lg-4" id="sticky_sidebar">
            <div className="profile-contactbox">
              <h3 className="contactBoxHeading">About My Project</h3>
              <div className="contactBoxInner">
                <form id="FrmQuote" method="post">
                  <input type="hidden" name="formid" value="1959F87" />
                  <input type="hidden" name="ip_address" id="ip_address" value="" />
                  <input type="hidden" name="browser" id="SC_browser" value="" />
                  <input type="hidden" name="trackingvars" className="trackingvars" id="trackingvars" />
                  <input
                    type="hidden"
                    name="timezone"
                    value=""
                    id="SC_fh_timezone"
                    className="SC_fh_timezone"
                  />
                  <input
                    type="hidden"
                    name="language"
                    id="SC_fh_language"
                    className="SC_fh_language"
                    value=""
                  />
                  <input
                    type="hidden"
                    name="capturepage"
                    id="SC_fh_capturepage"
                    className="SC_fh_capturepage"
                    value=""
                  />
                  <input type="hidden" name="vTags" id="vTags" value="#support #helpdesk #website_support" />
                  <input type="hidden" name="sc_lead_referer" id="sc_lead_referer" value="" />
                  <input type="hidden" name="iSubscriber" value="817" />
                  <input type="hidden" name="sc_referer_qstring" value="" id="sc_referer_qstring" />

                  <div className="form-video-row">
                    <div>
                      <div className="weight-300 mb-3">I'm looking to get this done:</div>
                      <div className="mb-3">
                        <textarea
                          className="form-control reqField"
                          required
                          name="reqMessage"
                          id="reqMessage"
                          rows="8"
                          placeholder="What do you need? How can we help?"
                        />
                      </div>
                    </div>
                    <div className="form-video mb-3">
                      <video>
                        <source src={process.env.GATSBY_STATIC_IMAGE_URL + "/video.mp4"} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                  <div className="mb-3 uploadBox">
                    <label htmlFor="formFile" className="form-label">
                      Optional: Upload Video
                    </label>
                    <input id="file-demo" type="file" className="form-control swift-gallery" required />
                    <small className="tipPart">
                      TIP: A short selfie-video with your phone can save you time & money!
                    </small>
                  </div>

                  <div className="signup-or-separator">
                    <span className="signup-or-separator--text">About You</span>
                    <hr />
                  </div>

                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control reqField"
                      name="reqName"
                      id="reqName"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control reqField"
                      name="reqEmailOffDomain"
                      id="reqEmailOffDomain"
                      placeholder="Email"
                      required
                    />
                    <input name="reqEmail" id="reqEmail" type="email" style={{ display: "none" }} />
                  </div>
                  <div className="mb-3">
                    <div className="row">
                      <div className="col-lg-6 col-md-9">
                        <input
                          type="text"
                          className="form-control swift_phone_field_us reqField"
                          name="reqPhone"
                          id="reqPhone"
                          placeholder="Phone"
                          required
                        />
                      </div>
                      <div className="col-lg-6 col-md-3">
                        <input
                          type="checkbox"
                          name="justTires"
                          id="justTiresCheckbox"
                          className="css-checkbox"
                          value="move"
                          checked="checked"
                        />
                        <label htmlFor="justTiresCheckbox" className="css-checkbox-label radGroup2 mb-0">
                          OK to SMS{" "}
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title="No spam, never sold, about your house or this property only."
                          >
                            <i className="bi bi-question-circle-fill" />
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control reqField"
                      name="reqAddress"
                      id="reqAddress"
                      placeholder="Address"
                    />
                  </div>

                  <div className="mb-2">
                    <button type="submit" className="btn btn-local btn-lg w-100 btnSubmit">
                      Request My Quote(s)
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Detail
