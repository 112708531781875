import * as React from "react"

const Gallery = () => {
  return (
    <section className="galleryPhotos">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="owl-carousel owl-theme">
              <div className="item">
                <a href={process.env.GATSBY_STATIC_IMAGE_URL + "/panel-300x300.jpg"} data-lightbox="gallery">
                  <img src={process.env.GATSBY_STATIC_IMAGE_URL + "/panel-300x300.jpg"} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Gallery
